import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MedicalSummaryService {
  constructor(
    @Inject('API_BASE') private api: any,
    @Inject('API_EXCAL_BASE') private apiExcal: any,
    private http: HttpClient
  ) {}

  getDemographics(userId: any, token: any) {
    let headers = new HttpHeaders().append('Authorization', `${token}`);

    let url = `${this.api}/medicalsummary/summary/profile?id=${userId}`;
    return this.http.get<any>(url, {
      headers: headers,
    });
  }
  getUserDetails(userId: any, token: any) {
    let headers = new HttpHeaders().append('Authorization', `${token}`);

    let url = `${this.apiExcal}/report/user/summary?userId=${userId}`;
    return this.http.get<any>(url, {
      headers: headers,
    });
  }
  getProvider(userId: any, token: any) {
    let headers = new HttpHeaders().append('Authorization', `${token}`);

    let url = `${this.api}/medicalsummary/summary/providers?id=${userId}`;
    return this.http.get<any>(url, {
      headers: headers,
    });
  }
  getDiagnosis(userId: any, token: any) {
    let headers = new HttpHeaders().append('Authorization', `${token}`);

    let url = `${this.api}/medicalsummary/summary/diagnosis?id=${userId}`;
    return this.http.get<any>(url, {
      headers: headers,
    });
  }
  getAlerts(userId: any, token: any) {
    let headers = new HttpHeaders().append('Authorization', `${token}`);

    let url = `${this.api}/medicalsummary/alerts/list?id=${userId}`;
    return this.http.get<any>(url, {
      headers: headers,
    });
  }

  getAlertsByDateRange(userId: any, token: any, startDate: any, endDate: any) {
    let headers = new HttpHeaders().append('Authorization', `${token}`);

    let url = `${this.apiExcal}/report/alerts/user?user=${userId}&startDate=${startDate}&endDate=${endDate}`;
    return this.http.get<any>(url, {
      headers: headers,
    });
  }

  getMedications(userId: any, token: any, startDate: any, endDate: any) {
    let headers = new HttpHeaders().append('Authorization', `${token}`);

    let url = `${this.apiExcal}/report/user/medication/active?user=${userId}&startDate=${startDate}&endDate=${endDate}`;
    return this.http.get<any>(url, {
      headers: headers,
    });
  }

  getNotes(userId: any, token: any) {
    let headers = new HttpHeaders().append('Authorization', `${token}`);

    let url = `${this.api}/comment/doctor?patientId=${userId}`;
    return this.http.get<any>(url, {
      headers: headers,
    });
  }
  getNotesByDateRange(userId: any, token: any, startDate: any, endDate: any) {
    let headers = new HttpHeaders().append('Authorization', `${token}`);

    let url = `${this.apiExcal}/report/user/providerNotes?userId=${userId}&startDate=${startDate}&endDate=${endDate}`;

    return this.http.get<any>(url, {
      headers: headers,
    });
  }
  // getMessages(userId: any) {

  //   let headers = new HttpHeaders().append('session', `${token}`);

  //   let url = `${this.api}/community?id=63e273b90c740820d61695c6`;
  //   return this.http.get<any>(url, {
  //     headers: {
  //       session: '63dd2f89cd419f11b2a0c2ba',
  //     },
  //   });
  // }

  getGraphDetailed(userId: any, startDate: any, endDate: any, token: any) {
    let headers = new HttpHeaders().append('Authorization', `${token}`);

    let url = `${this.api}/graph/detailed?id=${userId}&start=${startDate}&end=${endDate}&type=vital`;
    return this.http.get<any>(url, {
      headers: headers,
    });
  }
  getAllVitalById(
    userId: any,
    startDate: any,
    endDate: any,
    token: any
  ): Observable<any> {
    let headers = new HttpHeaders().append('Authorization', `${token}`);
    const url = `${this.apiExcal}/report/user/vitals?user=${userId}&startDate=${startDate}&endDate=${endDate}`;
    return this.http.get<any>(url, { headers: headers });
  }
  getLoggedInProviderDemographic(token: any): Observable<any> {
    let headers = new HttpHeaders().append('Authorization', `${token}`);
    const url = `${this.apiExcal}/demographic`;
    return this.http.get<any>(url, { headers: headers });
  }
}
