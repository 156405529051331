import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HighchartsChartModule } from 'highcharts-angular';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChartComponent } from './ChartComponent/app.chart.component';
import { MedicalSummaryComponent } from './medical-summary/medical-summary.component';
import { environment } from 'src/environments/environment';
import { TrendChartComponent } from './trend-chart/trend-chart.component';

@NgModule({
  declarations: [AppComponent, ChartComponent, MedicalSummaryComponent, TrendChartComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HighchartsChartModule,
    HttpClientModule,
  ],
  providers: [
    { provide: 'API_BASE', useValue: environment.apiV2Url },
    { provide: 'API_EXCAL_BASE', useValue: environment.apiExcalUrl },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
