import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import 'moment-timezone';
import { ChartCategory, ChartTypes } from '../ChartComponent/chart-types';
import { MedicalSummaryService } from './medical-summary.service';
export interface PeriodicElement {
  date: string;
  value: string;
  guidance: string;
}
import { map } from 'rxjs/operators';
import { chronicConditions, vitals } from './lookup.interface';
@Component({
  selector: 'app-medical-summary',
  templateUrl: './medical-summary.component.html',
  styleUrls: ['./medical-summary.component.css'],
})
export class MedicalSummaryComponent implements OnInit {
  unit: any;
  accountName: any;
  chartData: any = [];
  vitalName: any;
  chartTypeFilter: any;
  chartCategoryFilter: any;
  filterVal: any = '30';
  vitalCards: any = [];
  chartType = ChartTypes.LINE;
  chartCategory = ChartCategory.VITALS;
  chartTitle: any;
  isChart: boolean = false;
  chartDataV2: any = [];
  vitalData: any;
  timeZoneOffset: any;
  timeZoneName: any;
  providerTimeZoneOffset: any;
  providerTimeZoneName: any;
  today: any;
  chartUnit: any;
  vData: any;
  providerName: any;
  asNeeded: any;
  localDate: any;
  additionalProviderName: any = [];
  ac: any = [];
  chartV2Data: any = [];
  vitalHistory: any = [];
  primaryCondition: any;
  sex: any;
  loggedInProviderData: any;
  bloodSugarTrend: {trends:Array<{DataField:string , Min:number, Max:number, Average:number, Date:Date} >, unit:string} = {trends:[], unit:'mg/dL'};
  constructor(
    private medicalSummaryService: MedicalSummaryService,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  token: any;
  userData: any;

  alertData: any;
  userId: any;
  medData: any;
  graphData: any;
  startDate: any;
  endDate: any;
  defaultChart: boolean = true;
  notes: any;
  messages: any;
  diagnosisData: any;
  providerData: any;
  subArray: any = [];
  dataArray: any = [];
  additionalConditions: any = [];
  output: any = [];
  latestData: any;
  bp_vital: any;
  descriptionCleaned: any;

  ngOnInit(): any {
    this.route.queryParamMap.subscribe((params) => {
      this.token = params.get('session');
      this.timeZoneOffset = params.get('timeZoneOffset');
      this.providerTimeZoneOffset = params.get('providerTimeZoneOffset');
      this.userId = params.get('userId');
      this.startDate = params.get('startDate');
      this.endDate = params.get('endDate');
      this.accountName = params.get('accountName');
      this.timeZoneName = params.get('timeZoneName');
      this.providerTimeZoneName = params.get('providerTimeZoneName');

      this.getUserDetails();
      this.getNotesByDateRange();
      this.getTime();
      this.showMedications();

      this.getVitalData();
      this.getAlertsByDateRange();
      this.getLoggedInProviderDetails();
    });

    setTimeout(() => {
      this.isChart = true;
    }, 0);
  }
  getTime() {
    let date = moment();

    const localDateTime = moment.utc(date).tz(this.providerTimeZoneName);
    this.today = localDateTime.format('MM/DD/YYYY h:mm a');
  }
  isChartDataAvail = true;

  getUserDetails() {
    this.medicalSummaryService
      .getUserDetails(this.userId, this.token)
      .subscribe((response) => {
        this.userData = response.user;

        this.providerData = response.provider;
        this.diagnosisData = response.userDetails;
        this.sex =
          response.user.sex.charAt(0).toUpperCase() +
          response.user.sex.slice(1);
        this.additionalProviderName = response.additionalProviders.map(
          (a: any) => {
            return a.name;
          }
        );

        this.primaryCondition = chronicConditions[
          response.userDetails.PrimaryDisease
        ]
          ? chronicConditions[response.userDetails.PrimaryDisease].value
          : '';

        this.additionalConditions =
          response.userDetails.ChronicConditions.length > 0
            ? response.userDetails.ChronicConditions.map((item: any) => {
                return chronicConditions[item]
                  ? chronicConditions[item].value
                  : this.seprateAtUppercase(item);
              })
            : ['None'];
      });
  }
  seprateAtUppercase(word: any) {
    const regex = /[A-Z]/g;

    const separatedWord = word.replace(regex, (match: any) => ` ${match}`);

    const capitalizedWord =
      separatedWord.charAt(0).toUpperCase() + separatedWord.slice(1);

    return capitalizedWord;
  }

  showDiagnosis() {
    this.medicalSummaryService
      .getDiagnosis(this.userId, this.token)
      .subscribe((response) => {
        this.diagnosisData = response.message;

        for (const property in this.diagnosisData.chronicConditions) {
          this.additionalConditions.push(
            this.diagnosisData.chronicConditions[property]
          );
        }
      });
  }
  getLoggedInProviderDetails() {
    this.medicalSummaryService
      .getLoggedInProviderDemographic(this.token)
      .subscribe((response) => {
        this.loggedInProviderData = response;
      });
  }
  showProviders() {
    this.medicalSummaryService
      .getProvider(this.userId, this.token)
      .subscribe((response) => {
        this.providerData = response.message;
        this.providerName = this.providerData.associatedProvider.name
          .split(',')
          .reverse()
          .join(',');
        this.additionalProviderName = this.providerData.additionalProviders.map(
          (a: any) => {
            return a.name;
          }
        );
      });
  }
  showAlerts() {
    this.medicalSummaryService
      .getAlerts(this.userId, this.token)
      .subscribe((response) => {
        this.alertData = response.message.alerts;
        this.alertData.forEach((data: any) => {
          data.message = data.message.replace(/\[:BulletPoint\]/g, '\n');
        });
      });
  }
  getAlertsByDateRange() {
    this.medicalSummaryService
      .getAlertsByDateRange(
        this.userId,
        this.token,
        this.startDate,
        this.endDate
      )
      .subscribe((response) => {
        this.alertData = response;
        this.alertData.forEach((data: any) => {
          data.message = data.message.replace(/\[:BulletPoint\]/g, '\n');
        });
      });
  }
  showMedications() {
    this.medicalSummaryService
      .getMedications(this.userId, this.token, this.startDate, this.endDate)
      .subscribe((response) => {
        this.medData = response.scheduled;
        this.asNeeded = response.asNeeded;
      });
  }

  showNotes() {
    this.medicalSummaryService
      .getNotes(this.userId, this.token)
      .subscribe((response) => {
        this.notes = response.message;

        this.notes.forEach((data: any) => {
          data.time = moment.utc(data.time).tz(this.timeZoneName).format();
        });
      });
  }
  getNotesByDateRange() {
    this.medicalSummaryService
      .getNotesByDateRange(
        this.userId,
        this.token,
        this.startDate,
        this.endDate
      )
      .subscribe((response) => {
        this.notes = response;

        this.notes = this.notes.map((data: any) => {
          let providerTime = moment(data.Time)
            .tz(this.timeZoneName)
            .format(' h:mm a');
          let providerDate = moment(data.Time)
            .tz(this.timeZoneName)
            .format('MM/DD/YYYY');
          return {
            ...data,
            Time: providerTime,
            Date: providerDate,
          };
        });
      });
  }
  getVitalData() {
    this.chartData = [];

    this.chartTitle;
    this.defaultChart = false;
    this.isChart = false;
    this.chartUnit;

    this.medicalSummaryService
      .getAllVitalById(this.userId, this.startDate, this.endDate, this.token)
      .subscribe((allVitals: any) => {
        let response = allVitals.vitals;
        if (allVitals.bloodSugarTrend && allVitals.bloodSugarTrend.trends?.length > 0){

          this.bloodSugarTrend ={unit: allVitals.bloodSugarTrend.unit, trends: allVitals.bloodSugarTrend.trends.map((trend: { Date: moment.MomentInput; }) =>{  return {  ...trend,  Date: moment.utc(trend.Date).tz(this.timeZoneName).format('MM/DD/YYYY h:mm a')  } })};

        }

        let arr: any = [];
        let tempData: any = [];
        let legend: any = [];

        response.forEach((data: any) => {
          if (data.vital == 'Temperature') {
            const temperatures = data.vitalHistory;
            data.unit = 'Fahrenheit';
            for (let i = 0; i < temperatures.length; i++) {
              const celsius = parseFloat(temperatures[i].value);
              const fahrenheit = celsius * 1.8 + 32;
              temperatures[i].value = fahrenheit.toFixed(1);
            }
          }
          if (data.vital == 'Weight') {
            data.unit = 'lbs';
            const weights = data.vitalHistory;

            for (let i = 0; i < weights.length; i++) {
              const kgs = parseFloat(weights[i].value);
              const lbs = kgs * 2.20462;
              weights[i].value = lbs.toFixed(1);
            }
          }
          if (data.vital != 'Temperature' && data.vital != 'Weight') {
            const vitals = data.vitalHistory;
            for (let i = 0; i < vitals.length; i++) {
              const value = parseFloat(vitals[i].value);
              vitals[i].value = value.toFixed(0);
            }
          }
          if (
            data.vital === 'BloodPressure_Diastolic' ||
            data.vital === 'BloodPressure_Systolic'
          ) {
            if (data.vitalHistory.length > 0) {
              arr.push(data.vitalHistory);
              legend.push({ val: data.vital });
            } else {
              arr.push(data.latestValue);
              legend.push({ val: data.vital });
            }
            tempData = {
              vital: 'Blood Pressure',
              vitalLeg: legend,
              latestValue: data.latestValue,
              unit: data.unit,
              latestDate: data.latestDate,
              vitalHistory: arr,
            };
          } else {
            data.vital = vitals[data.vital].value;
          }
        });
        response.push(tempData);

        const vitalData = response.filter(
          (e: any) =>
            e.vital !== 'BloodPressure_Systolic' &&
            e.vital !== 'BloodPressure_Diastolic'
        );
        const data = vitalData;
        this.vData = vitalData;

        for (let i = 0; i < this.vData.length; i++) {
          let vitalHistory: any = [];

          if (this.vData[i].vital === 'Blood Pressure') {
            if (
              this.vData[i].vitalHistory[0].length &&
              this.vData[i].vitalHistory[1].length
            ) {
              let bloodpressure = '';

              this.vData[i].vitalHistory[0].forEach((diastolic: any) => {
                this.vData[i].vitalHistory[1].forEach((systolic: any) => {
                  if (diastolic.date === systolic.date) {
                    bloodpressure = `${systolic.value}/${diastolic.value}`;

                    const localDateTime = moment
                      .utc(diastolic.date)
                      .tz(this.timeZoneName);

                    vitalHistory.push({
                      value: bloodpressure ? bloodpressure : 0,
                      date: localDateTime.format('MM/DD/YYYY h:mm a'),
                    });
                  }
                });
              });
              this.vitalHistory[this.vData[i].vital] = vitalHistory;
            }
          } else {
            this.vData[i].vitalHistory.forEach((vital: any) => {
              const localDateTime = moment
                .utc(vital.date)
                .tz(this.timeZoneName);
              vitalHistory.push({
                value: vital.value,
                date: localDateTime.format('MM/DD/YYYY h:mm a'),
              });
            });

            this.vitalHistory[this.vData[i].vital] = vitalHistory;
          }
        }

        let newDataArray = [];
        let subArray = [];

        for (let i = 0; i < data.length; i++) {
          subArray.push(data[i]);

          if ((i + 1) % 2 === 0 || i === data.length - 1) {
            newDataArray.push(subArray);
            subArray = [];
          }
        }
        this.chartData = newDataArray;

        this.isChart = true;

      });
  }
  getGraphData() {
    this.chartV2Data = [];

    this.chartTitle;
    this.defaultChart = false;
    this.isChart = false;
    this.chartUnit;

    this.medicalSummaryService
      .getGraphDetailed(this.userId, this.startDate, this.endDate, this.token)
      .subscribe((response: any) => {
        let v2vitalData = response.message;
        v2vitalData.forEach((data: any) => {
          for (const key in data.collections) {
            let vital = data.collections[key];

            for (let i = 0; i < data.collections[key].length; i++) {
              const localDateTime = moment
                .utc(data.collections[key][i].date)
                .tz(this.timeZoneName);
              data.collections[key][i].date =
                localDateTime.format('MM/DD/YYYY h:mm a');
            }
          }

          if (data.title == 'Temperature') {
            const temperatures = data.collections.temperature;
            data.tooltipSuffix = 'Fahrenheit';
            for (let i = 0; i < temperatures.length; i++) {
              const celsius = parseFloat(temperatures[i].value);
              const fahrenheit = celsius * 1.8 + 32;
              temperatures[i].value = fahrenheit.toFixed(1);
              temperatures[i].tooltip = fahrenheit.toFixed(1) + 'F';
              temperatures[i].tooltipSuffix = 'F';
            }
          }
          if (data.title == 'Oxygen') {
            data.title = 'Blood Oxygen';
          }
          if (data.title == 'Weight') {
            data.tooltipSuffix = 'lbs';
            const weights = data.collections.weight;
            for (let i = 0; i < weights.length; i++) {
              const kgs = parseFloat(weights[i].value);
              const lbs = kgs * 2.20462;
              weights[i].value = lbs.toFixed(1);
              weights[i].tooltip = lbs.toFixed(1) + 'lbs';
              weights[i].tooltipSuffix = 'lbs';
            }
          }
        });

        const data = v2vitalData;

        this.vData = v2vitalData;

        let unit;
        let BloodPressure = [];
        for (let i = 0; i < this.vData.length; i++) {
          if (this.vData[i].title === 'Blood Pressure') {
            BloodPressure = this.vData[i].collections;
          }
          this.vitalHistory[this.vData[i].title] = [];

          for (const key in this.vData[i].collections) {
            this.vData[i].collections[key].forEach((item: any) => {
              if (this.vData[i].title !== 'Blood Pressure') {
                this.vitalHistory[this.vData[i].title].push({
                  date: item.date,
                  value: item.value,
                });
              }
            });
          }
        }
        for (const bpd of BloodPressure['bloodpressure_diastolic']) {
          const bloodpressure_systolic = BloodPressure[
            'bloodpressure_systolic'
          ].find((bps: any) => moment(bps.date).isSame(moment(bpd.date)));
          if (bloodpressure_systolic) {
            this.vitalHistory['Blood Pressure'].push({
              date: bpd.date,
              value: `${bloodpressure_systolic.value}/${bpd.value}`,
            });
          }
        }

        let newDataArray = [];
        let subArray = [];

        for (let i = 0; i < data.length; i++) {
          subArray.push(data[i]);

          if ((i + 1) % 2 === 0 || i === data.length - 1) {
            newDataArray.push(subArray);
            subArray = [];
          }
        }
        this.chartDataV2 = newDataArray;

        this.isChart = true;
      });
  }
}
