import {Component, Input, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-trend-chart',
  templateUrl: './trend-chart.component.html',
  styleUrls: ['./trend-chart.component.css']
})
export class TrendChartComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};

  @Input() bloodSugarTrend : {trends:Array<{DataField:string , Min:number, Max:number, Average:number, Date:Date} >, unit:string} = {trends:[], unit:'mg/dL'};
  constructor() { }

  ngOnInit(): void {

    this.chartOptions = {
      title: {
        text: 'Blood Sugar Trend'
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Date'
        },
        tickInterval: 24 * 3600 * 1000, // one day
        dateTimeLabelFormats: {
          day: '%y-%m-%d'
        }
      },
      yAxis: {
        title: {
          text: 'Value'
        }
      },
      series: [
        {
          type: 'line',
          name: 'Min',
          data: this.bloodSugarTrend.trends.map(trend => [new Date(trend.Date).getTime(), trend.Min])
        },
        {
          type: 'line',
          name: 'Average',
          data: this.bloodSugarTrend.trends.map(trend => [new Date(trend.Date).getTime(), trend.Average])
        },
        {
          type: 'line',
          name: 'Max',
          data: this.bloodSugarTrend.trends.map(trend => [new Date(trend.Date).getTime(), trend.Max])
        }
      ]
    };
  }

  private getChartOptions() {
    return undefined;
  }
}
