export const lineChartConfig: any = {
  chart: {
    zoomType: 'x',
    height: '150',
    backgroundColor: '#f8f9fa',
  },
  tooltip: {
    shared: true,
    useHTML: true,
    style: {
      color: '#E9ECEF',
      fontSize: '11px',
      padding: '0',
    },
    backgroundColor: null,
    shadow: false,
    borderWidth: 0,
    headerFormat: '<b>{point.x:%e %b %H:%M}</b>',
    pointFormat:
      '<p style="font-size:12px;margin:0px;padding-top:1px;padding-bottom:1px;">{series.name.name} <strong>{point.y}</strong> <strong>{series.name.unit}</strong></p>',
  },

  legend: {
    shared: true,
    useHTML: true,
    style: {
      color: '#E9ECEF',
      fontSize: '11px',
      padding: '0',
    },
    backgroundColor: null,
    shadow: false,
    borderWidth: 0,
    labelFormat: '',
  },
  exporting: { enabled: false },
  xAxis: {
    labels: {
      rotation: -45,
      style: {
        fontSize: '8px',
      },
    },

    categories: [],
    crosshair: false,
  },
  yAxis: {
    text: false,
  },
  series: [],
};
export const chartDefaultConfig: any = {
  credits: {
    enabled: false,
  },
  exporting: { enabled: false },
  title: {
    text: '',
  },
};
export const linechart: any = {
  series: [],
  chart: {
    type: 'line',
  },
  tooltip: {
    shared: true,
    useHTML: true,
    style: {
      color: '#73C2FB',
      fontSize: '11px',
      padding: '0',
    },
    backgroundColor: null,
    shadow: false,
    borderWidth: 0,
    // headerFormat: '<b>{point.x:%e %b %H:%M}</b>',
    pointFormat:
      '<p style="font-size:12px;margin:0px;padding-top:1px;padding-bottom:1px;">{series.name.name} <strong>{point.y}</strong> <strong>{series.name.unit}</strong></p>',
  },
  legend: {
    shared: true,
    useHTML: true,

    style: {
      color: '#E9ECEF',
      fontSize: '11px',
      padding: '0',
    },
    backgroundColor: null,
    shadow: false,
    borderWidth: 0,
    labelFormat: '',
    title: {},
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
    align: 'center',
    verticalAlign: 'bottom',
    style: {
      color: '#000',
      fontSize: '16px',
      fontWeight: 'bold',
    },
  },
  xAxis: {
    categories: [],
    labels: {
      rotation: -45,
      style: {
        color: '#000',
        fontSize: '8px',
      },
    },
    crosshair: false,
  },
  yAxis: {
    title: true,
    // gridLineDashStyle: 'Dot',
    // gridLineColor: '#000',
    // tickInterval: 10,
  },
};
export const linechartv2: any = {
  series: [],
  chart: {
    type: 'line',
  },
  tooltip: {
    // shared: true,
    // useHTML: true,
    style: {
      color: '#73C2FB',
      fontSize: '11px',
      padding: '0',
    },
    // backgroundColor: null,
    // shadow: false,
    // borderWidth: 0,
    //headerFormat: '<b>{point.x:%e %b %H:%M}</b>',
    pointFormat:
      '<p style="font-size:12px;margin:0px;padding-top:1px;padding-bottom:1px;">{series.name.name} <strong>{point.y}</strong> <strong>{series.name.unit}</strong></p>',
  },
  // legend: {
  //   shared: true,
  //   useHTML: true,
  //   style: {
  //     color: '#E9ECEF',
  //     fontSize: '11px',
  //     padding: '0',
  //   },
  //   backgroundColor: null,
  //   shadow: false,
  //   borderWidth: 0,
  //   labelFormat: '',
  //   title: {},
  // },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
    align: 'center',
    verticalAlign: 'bottom',
    style: {
      color: '#000',
      fontSize: '16px',
      fontWeight: 'bold',
    },
  },
  xAxis: {
    categories: [],
    labels: {
      rotation: -45,
      style: {
        color: '#000',
        fontSize: '8px',
      },
    },
    crosshair: false,
  },
  yAxis: {
    title: {
      text: 'Activity',
    },
    // gridLineDashStyle: 'Dot',
    // gridLineColor: '#000',
    // tickInterval: 10,
  },
};
