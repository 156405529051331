<div class="container">
  <div>
    <img src="../assets/medocitylogo.png" width="125" />
  </div>
  <table>
    <tbody>
      <tr>
        <td>
          <main class="main-container">
            <div class="title">Patient Summary</div>
            <div class="address">
              <table class="ph-table" style="width: 100%; margin-top: 20px">
                <thead>
                  <tr class="table-title-header bg-header">
                    <th style="width: 100px">Name</th>
                    <th style="width: 75px">DOB</th>
                    <th style="width: 50px">Age</th>

                    <th style="width: 75px">Gender</th>
                    <th style="width: 100px">MRN</th>
                    <th>Account Name</th>
                    <th style="width: 120px">Platform Team Member</th>
                    <th style="width: 120px">Medical Provider</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      {{ userData?.firstName + " " + userData?.lastName }}
                    </td>
                    <td class="text-center">
                      {{ userData?.birthDate | date : "mediumDate" }}
                    </td>
                    <td class="text-center">{{ userData?.age }}</td>
                    <td class="text-center">
                      {{ sex }}
                    </td>
                    <td class="text-center">{{ userData?.mrn }}</td>
                    <td class="text-center">{{ accountName }}</td>
                    <td class="text-center">
                      {{
                        providerData?.firstName + " " + providerData?.lastName
                      }}
                    </td>
                    <td class="pl5 no-border">
                      <tr *ngFor="let ap of additionalProviderName">
                        <td>{{ ap }}</td>
                      </tr>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style="margin-top: 20px">
              <table class="ph-table" style="width: 100%">
                <thead>
                  <tr class="table-title-header bg-header">
                    <th>Primary Condition</th>
                    <th>Additional Conditions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center" style="width: 50%">
                      {{ primaryCondition }}
                    </td>
                    <td class="pl6 no-border" style="width: 50%">
                      <tr
                        *ngFor="let ac of additionalConditions; let last = last"
                      >
                        <td>{{ ac }}{{ !last ? "," : "" }}</td>
                      </tr>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="chart-block">
              <table style="width: 100%">
                <tr *ngFor="let vital of chartData">
                  <td *ngFor="let vitalData of vital">
                    <app-chart
                      [chartData]="vitalData"
                      [chartType]="chartType"
                      [chartCategory]="chartCategory"
                      [chartUnit]="chartUnit"
                    ></app-chart>
                  </td>
                </tr>

              </table>
            </div>
            <div>
              <table>
                <tr *ngIf="bloodSugarTrend && bloodSugarTrend.trends.length > 0">
                  <td style="width: 100%;">
                    <app-trend-chart [bloodSugarTrend]="bloodSugarTrend"></app-trend-chart>
                  </td>
                </tr>
              </table>
            </div>

            <div
              class="patient-history"
              [ngStyle]="{
                'page-break-before': isChartDataAvail ? 'always' : 'avoid'
              }"
            >
              <table style="width: 100%">
                <tr colspan="12" *ngFor="let vital of vData">
                  <td style="margin-top: 40px">
                    <table
                      class="ph-table"
                      style="width: 100%; margin-top: 20px"
                    >
                      <thead>
                        <tr class="table-title-header bg-header">
                          <th colspan="12">
                            {{ vital?.vital + " (" + vital?.unit + ")" }}
                          </th>
                        </tr>
                        <tr class="table-title-header bg-header">
                          <th>Date</th>

                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let vital of vitalHistory[vital.vital]">
                          <td class="text-center" style="width: 50%">
                            {{ vital?.date }}
                          </td>

                          <td class="text-center" style="width: 50%">
                            {{ vital?.value }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>

              <table class="ph-table" style="width: 100%; margin-top: 40px" *ngIf="bloodSugarTrend.trends.length > 0">
                <thead>
                <tr class="table-title-header bg-header">
                  <th colspan="12">Blood Sugar({{bloodSugarTrend.unit}})-(Streaming - Interval: 1 hour)</th>
                </tr>
                <tr class="table-title-header bg-header">
                  <th>Date</th>
                  <th>Min</th>
                  <th>Max</th>
                  <th>Average</th>

                </tr>
                </thead>
                <tbody>
                <tr
                  *ngFor="let bs of bloodSugarTrend.trends"
                  style="page-break-inside: avoid"
                >
                  <td class="text-center">{{ bs?.Date  }}</td>
                  <td class="text-center">
                    {{ bs?.Min | number:'1.0-0' }}
                  </td>
                  <td class="text-center">{{ bs?.Max | number:'1.0-0' }}</td>
                  <td
                    class="text-center"
                    style="width: 40%; padding: 10px; white-space: pre-wrap"
                  >
                    {{ bs?.Average | number:'1.0-0'}}
                  </td>

                </tr>
                </tbody>
              </table>

              <table class="ph-table" style="width: 100%; margin-top: 40px">
                <thead>
                  <tr class="table-title-header bg-header">
                    <th colspan="12">Alerts</th>
                  </tr>
                  <tr class="table-title-header bg-header">
                    <th>Severity</th>
                    <th>Date</th>
                    <th>Symptom</th>
                    <th>Description</th>
                    <th>Resolved By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let alert of alertData"
                    style="page-break-inside: avoid"
                  >
                    <td class="text-center">{{ alert?.severity }}</td>
                    <td class="text-center">
                      {{ alert?.date | date : "MM/dd/yyyy" }}
                    </td>
                    <td class="text-center">{{ alert?.symptom }}</td>
                    <td
                      class="text-center"
                      style="width: 40%; padding: 10px; white-space: pre-wrap"
                    >
                      {{ alert?.message }}
                    </td>
                    <td class="text-center">{{ alert?.resolvedBy.name }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="ph-table" style="width: 100%; margin-top: 40px">
                <thead>
                  <tr class="table-title-header bg-header">
                    <th colspan="12">Active Medications</th>
                  </tr>
                  <tr>
                    <th colspan="12" style="text-align: center">
                      Scheduled Medications
                    </th>
                  </tr>
                  <tr class="table-title-header bg-header">
                    <th>Medication</th>
                    <th>Info</th>
                    <th>Missed</th>
                    <th>Taken</th>
                    <th>Unreported</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let med of medData"
                    style="page-break-inside: avoid"
                  >
                    <td class="text-center">
                      {{ med?.name }}
                    </td>
                    <td class="text-center">{{ med?.directions }}</td>
                    <td class="text-center">{{ med?.missed }} %</td>
                    <td class="text-center">{{ med?.taken }} %</td>
                    <td class="text-center">{{ med?.unreported }} %</td>
                  </tr>
                  <tr>
                    <th colspan="12" style="text-align: center">
                      AsNeeded Medications
                    </th>
                  </tr>
                  <tr class="table-title-header bg-header">
                    <th>Medication</th>
                    <th>Info</th>
                    <th>Missed</th>
                    <th>Taken</th>
                    <th>Unreported</th>
                  </tr>
                  <tr
                    *ngFor="let med of asNeeded"
                    style="page-break-inside: avoid"
                  >
                    <td class="text-center">
                      {{ med?.name }}
                    </td>
                    <td class="text-center">{{ med?.strength }}</td>
                    <td class="text-center">{{ med?.missed }} %</td>
                    <td class="text-center">{{ med?.taken }} %</td>
                    <td class="text-center">{{ med?.unreported }} %</td>
                  </tr>
                </tbody>
              </table>
              <table class="ph-table" style="width: 100%; margin-top: 40px">
                <thead>
                  <tr class="table-title-header bg-header">
                    <th colspan="12">Provider Notes</th>
                  </tr>
                  <tr class="table-title-header bg-header">
                    <th>Date</th>
                    <th>Time</th>
                    <th>Provider Name</th>

                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let note of notes"
                    style="page-break-inside: avoid"
                  >
                    <td class="text-center">
                      {{ note?.Date }}
                    </td>
                    <td class="text-center">
                      {{ note?.Time }}
                    </td>
                    <td class="text-center">{{ note?.UserName }}</td>

                    <td class="pl5 text-center">{{ note?.Text }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </main>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>
          <!--place holder for the fixed-position footer-->
          <div class="page-footer-space"></div>
        </td>
      </tr>
    </tfoot>
  </table>

  <br class="page-footer" />
  <footer>
    Date Range: Custom (Start: {{ startDate | date : "MM/dd/yyy" }}, End:
    {{ endDate | date : "MM/dd/yyy" }}), Patient:
    {{ userData?.firstName + " " + userData?.lastName }}, Sections: All, Note
    Range: All<br />CONFIDENTIAL Report Created:
    {{ today }}
    by
    {{ loggedInProviderData?.firstName + " " + loggedInProviderData?.lastName }}
    <br />
  </footer>
</div>
