import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { ChartCategory, ChartTypes } from './chart-types';

import {
  linechart as lineChartOption,
  chartDefaultConfig,
} from './chart.config';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  constructor() {}
  setDefaultOptions(highcharts: typeof Highcharts) {
    highcharts.setOptions(chartDefaultConfig);
  }
  getLineChartOptions(
    lineChartData: any,
    chartCategory: string,
    chartUnit?: string,
    chartTitle?: string
  ) {
    let mData: any;
    let mCat: any;
    let multiArr = lineChartData.vitalHistory.filter(Array.isArray).length;

    let lineChartTitle = lineChartData.vital;
    let lineChartUnit = lineChartData.unit;

    if (multiArr > 0) {
      let seriesArr: any = [];
      let catArr: any = [];
      let color: any = ['', '#455796'];
      let label: any = [];

      lineChartData.vitalHistory.forEach((data: any, index: any) => {
        if (chartCategory === ChartCategory.VITALS) {
          mData = this.getLineChartData(data);
          mCat = this.getLineChartDataCat(data);
        }

        seriesArr.push({
          type: ChartTypes.LINE,
          showInLegend: true,

          name: lineChartData.vitalLeg[index].val,
          color: color[index],
          data: mData,
          turboThreshold: mData.length + 1,
        });
        seriesArr = seriesArr.reverse();
      });
      lineChartOption.series = seriesArr;

      lineChartOption.title = {
        text: lineChartTitle,
      };

      lineChartOption.xAxis = {
        type: 'datetime',
        categories: mCat,
        labels: {
          rotation: -45,
          style: {
            fontSize: '8px',
          },
        },

        crosshair: false,
      };
      lineChartOption.yAxis = {
        title: {
          text: lineChartUnit,
        },
      };
    } else {
      if (chartCategory === ChartCategory.VITALS) {
        mData = this.getLineChartData(lineChartData.vitalHistory);
        mCat = this.getLineChartDataCat(lineChartData.vitalHistory);
      }

      lineChartOption.series = [
        {
          showInLegend: false,
          name: {
            name: lineChartData.vital,
            unit: lineChartUnit,
          },
          type: ChartTypes.LINE,
          data: mData,
          turboThreshold: mData.length + 1,
        },
      ];
      // lineChartOption.legend.labelFormat = lineChartData.vital;
      lineChartOption.title = {
        text: lineChartTitle,
      };

      lineChartOption.xAxis = {
        type: 'datetime',
        categories: mCat,
        labels: {
          rotation: -45,
          style: {
            fontSize: '8px',
          },
        },
      };
      lineChartOption.yAxis = {
        title: {
          text: lineChartUnit,
        },
      };
    }
    return lineChartOption;
  }

  getLineChartData(chartData: any) {
    const data = [];
    for (const key in chartData) {
      let customDate = new Date(chartData[key].date).valueOf();
      data.push([chartData[key].date, parseInt(chartData[key].value)]);
    }

    return data;
  }

  getLineChartDataCat(chartData: any) {
    const data: any = [];
    for (const key in chartData) {
      let customDate = moment(chartData[key].date).format('DD-MM-YYYY');
      data.push([customDate]);
    }

    return data;
  }
  getLineChartV2Options(
    lineChartData: any,
    chartCategory: string,
    chartUnit?: string,
    chartTitle?: string
  ) {
    let mData: any;
    let mCat: any;
    let vital: any;
    let multiArr = lineChartData.collections;

    for (const key in multiArr) {
      vital = multiArr[key];
    }

    let lineChartTitle = lineChartData.title;
    let lineChartUnit: any;

    if (lineChartTitle == 'Blood Pressure') {
      let seriesArr: any = [];
      let catArr: any = [];
      let label: any = [];
      lineChartUnit = lineChartData.tooltipSuffix;
      for (const key in multiArr) {
        vital = multiArr[key];

        if (chartCategory === ChartCategory.VITALS) {
          mData = this.getLineChartV2Data(vital);

          mCat = this.getLineChartDataV2Cat(vital);
        }

        seriesArr.push({
          type: ChartTypes.LINE,
          showInLegend: true,

          name: key,

          data: mData,
        });
      }
      lineChartOption.series = seriesArr;

      lineChartOption.title = {
        text: lineChartTitle,
      };

      lineChartOption.xAxis = {
        type: 'datetime',
        categories: mCat,
        labels: {
          rotation: -45,
          style: {
            fontSize: '8px',
          },
        },

        crosshair: false,
      };
      lineChartOption.yAxis = {
        title: {
          text: lineChartUnit,
        },
      };
    } else {
      if (chartCategory === ChartCategory.VITALS) {
        mData = this.getLineChartV2Data(vital);

        mCat = this.getLineChartDataV2Cat(vital);

        lineChartUnit = lineChartData.tooltipSuffix;
      }

      lineChartOption.series = [
        {
          showInLegend: false,
          name: {
            name: lineChartData.title,
            unit: lineChartUnit,
          },
          type: ChartTypes.LINE,
          data: mData,
        },
      ];

      lineChartOption.title = {
        text: lineChartTitle,
      };

      lineChartOption.xAxis = {
        type: 'datetime',
        categories: mCat,
        labels: {
          rotation: -45,
          style: {
            fontSize: '8px',
          },
        },
      };
      lineChartOption.yAxis = {
        title: {
          text: lineChartUnit,
        },
      };
    }
    return lineChartOption;
  }
  getLineChartV2Data(chartDataV2: any) {
    const data = [];
    for (const key in chartDataV2) {
      let customDate = new Date(chartDataV2[key].date).valueOf();
      data.push([chartDataV2[key].date, parseInt(chartDataV2[key].value)]);
    }

    return data;
  }
  getLineChartDataV2Cat(chartDataV2: any) {
    const data: any = [];
    for (const key in chartDataV2) {
      let customDate = moment(chartDataV2[key].date).format('DD-MM-YYYY');
      data.push([customDate]);
    }

    return data;
  }
}
