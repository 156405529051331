export const chronicConditions: any = {
  adhd: {
    value: 'ADHD',
  },
  alzheimersDisease: {
    value: 'Alzheimers Disease',
  },
  anemia: {
    value: 'Anemia',
  },
  anxiety: {
    value: 'Anxiety',
  },
  arthritis: {
    value: 'Arthritis',
  },
  asthma: {
    value: 'Asthma',
  },
  atrialFibrillation: {
    value: 'Atrial Fibrillation',
  },
  bipolarDepression: {
    value: 'Bipolar Depression',
  },
  bypassSurgery: {
    value: 'Bypass Surgery',
  },
  cancer: {
    value: 'Cancer',
  },
  chronicKidneyDisease: {
    value: 'Chronic Kidney Disease',
  },
  chronicObstructivePulmonaryDisease: {
    value: 'Chronic Obstructive Pulmonary Disease',
  },
  coronaryArteryDisease: {
    value: 'Coronary Artery Disease',
  },
  crohnsDisease: {
    value: 'Crohn’s Disease',
  },
  dementia: {
    value: 'Dementia',
  },
  depression: {
    value: 'Depression',
  },
  diabetes: {
    value: 'Diabetes',
  },
  diversion: {
    value: 'Diversion',
  },
  electroConvulsiveTherapy: {
    value: 'Electro-Convulsive Therapy',
  },
  endStageRenalDisease: {
    value: 'End Stage Renal Disease',
  },
  esophagectomy: {
    value: 'Esophagectomy',
  },
  gerd: {
    value: 'GERD',
  },
  glaucoma: {
    value: 'Glaucoma',
  },
  heartFailure: {
    value: 'Heart Failure',
  },
  heartFailureLVAD: {
    value: 'Heart Failure w/LVAD',
  },
  hyperlipidemia: {
    value: 'Hyperlipidemia',
  },
  hypercholesterolemia: {
    value: 'Hypercholesterolemia',
  },
  hypertension: {
    value: 'Hypertension',
  },
  lowerExtremityJointReplacement: {
    value: 'Lower Extremity Joint Replacement',
  },
  ibs: {
    value: 'IBS',
  },
  ileoconduit: {
    value: 'Ileo Conduit',
  },
  insomnia: {
    value: 'Insomnia',
  },
  noDisease: {
    value: 'No Disease',
  },
};
export const vitals: any = {
  Oxygen: {
    value: 'Blood Oxygen',
  },
  BloodSugar: {
    value: 'Blood Sugar',
  },
  Temperature: {
    value: 'Temperature',
  },
  Weight: {
    value: 'Weight',
  },
  Activity: {
    value: 'Activity',
  },
  BloodPressure: {
    value: 'Blood Pressure',
  },

  HeartRate: {
    value: 'Heart Rate',
  },
  Movement: {
    value: 'Movement',
  },
  SkinTemperature: {
    value: ' Skin Temperature',
  },
  SleepDeep: {
    value: 'Sleep Deep',
  },
  SleepLight: {
    value: 'Sleep Light',
  },
  SleepEfficiency: {
    value: 'Sleep Efficiency',
  },
  HeartRateVariability: {
    value: 'Heart Rate Variability',
  },
  SleepTimesWoken: {
    value: 'Sleep Times Woken',
  },
  SleepREM: {
    value: 'Sleep REM',
  },
  SleepQuality: {
    value: 'Sleep Quality',
  },
};
