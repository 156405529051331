import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ChartTypes } from './chart-types';
import { ChartService } from './chart.service';

@Component({
  selector: 'app-chart',
  providers: [ChartService],
  templateUrl: './app.chart.component.html',
})
export class ChartComponent implements OnChanges {
  @Input() chartData: any;
  @Input() chartDataV2: any;
  @Input() chartType = '';
  @Input() chartCategory = '';
  @Input() chartLegend = '';
  @Input() chartUnit = '';
  @Input() chartTitle = '';
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};

  constructor(private chartService: ChartService) {
    chartService.setDefaultOptions(Highcharts);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      const { chartData, chartType, chartCategory, chartUnit, chartTitle } =
        changes;

      if (property === 'chartData' && chartData.currentValue) {
        this.buildGraph(
          chartData.currentValue,
          chartType?.currentValue,
          chartCategory?.currentValue,
          chartUnit?.currentValue,
          chartTitle?.currentValue
        );
      }
    }
  }
  private buildGraph<T>(
    chartData: any,

    chartType: string,
    chartCategory: string,
    chartUnit?: string,
    chartTitle?: string
  ) {
    this.chartOptions = this.chartService.getLineChartOptions(
      chartData,
      chartCategory,
      chartUnit,
      chartTitle
    );
    // this.chartOptions = this.chartService.getLineChartV2Options(
    //   chartData,
    //   chartCategory,
    //   chartUnit,
    //   chartTitle
    // );
  }
}
